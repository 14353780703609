.Cards {
    display: flex;
    flex-direction: column;
    align-items: center;

    .addCardBtn {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 5px;
        border: none;
        font-size: 16px;

        &:hover {
            background-color: var(--level2);
            cursor: pointer;
        }
    }

    a {
        text-decoration: none;
    }

    .cardsList {
        display: flex;
        overflow: scroll;
        gap: 15px;
        padding: 20px;
        flex-wrap: wrap;
    }
}

.MyCards {
    .emptyMyCards {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}