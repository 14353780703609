.CardPage {

    .frmae {
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .textHeader {
            background-color: rgba(118, 118, 118, 0.433);
            backdrop-filter: blur(10px);
            padding: 30px;
            text-align: center;
            border-radius: 10px;

            b hr {
                margin: 20px 0 20px 0;
            }

            h1 {
                line-height: 1em;
            }
        }
    }

    .content {
        .contentHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            background-color: #78787815;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 35px;
            border-radius: 5px;

            a {
                text-decoration: none;
            }

            button {
                padding: 2px 15px;
                display: flex;
                align-items: center;
                gap: 5px;
                border-radius: 5px;
                border: none;
                font-size: 14px;

                &:hover {
                    background-color: var(--level2);
                    cursor: pointer;
                }
            }

            .share {
                display: flex;
                flex-direction: row;
                gap: 20px;
                justify-content: flex-end;
                align-items: center;

                a:hover {
                    color: var(--level2);
                }
            }
        }

        .contentBody {
            p {
                white-space: break-spaces;
            }
        }
    }
}