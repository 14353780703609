.CardsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;

    .addCardBtn {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 5px;
        border: none;
        font-size: 16px;

        &:hover {
            background-color: var(--level2);
            cursor: pointer;
        }
    }

    a {
        text-decoration: none;
    }

    .tableFrame {
        width: 100%;
        overflow: scroll;
        padding: 20px;
        display: flex;

        table {
            margin: 0 auto;
            border-collapse: separate;
            border-spacing: 0px 5px;

            thead {
                tr {
                    height: 30px;
                    background-color: #e9e9e9;
                }

                th {
                    font-weight: 800;
                    font-size: 16px;
                }
            }

            tbody {

                tr {
                    td {
                        text-align: right;
                        padding: 10px 40px;
                        font-size: 14px;

                        img {
                            height: 35px;
                            width: 35px;
                            object-position: center;
                            object-fit: cover;
                            border-radius: 50%;
                            font-size: 14px;
                        }

                        .actions {
                            display: flex;
                            gap: 15px;

                            svg {
                                font-size: 20px;
                            }
                        }
                    }

                }
            }
        }
    }
}