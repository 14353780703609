.About {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 40px 150px 40px;
}


.About section>h3 {
    display: inline-block;
    background-color: var(--mainColor);
    color: black;
    padding: 0 10px;
    border-radius: 5px;
    box-shadow: 0 0 25px -10px var(--mainColor);
    margin-bottom: 10px;
}

.About p,
li {
    text-align: justify;
    font-size: clamp(13px, 2vw, 16px);
}

.About ul {
    list-style: inside circle;
}

.About ul li {
    margin-bottom: 5px;
}