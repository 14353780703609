.Card {
  background-size: cover;
  border-radius: 15px;
  min-width: 250px;
  width: 250px;
  min-height: 400px;
  -webkit-box-shadow: 0 0 30px -20px black;
          box-shadow: 0 0 30px -20px black;
  background-color: #fff;
  background-position: center;
}

.Card .cardFrame {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff00), to(#000));
  background-image: linear-gradient(#ffffff00, #000);
  border-radius: 15px;
  padding: 25px;
  -webkit-backdrop-filter: brightness(0.9);
          backdrop-filter: brightness(0.9);
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  overflow: scroll;
}

.Card .cardFrame a {
  text-decoration: none;
  font-size: 1em;
}

.Card .cardFrame a :hover {
  text-decoration: underline;
}

.Card .cardFrame h2 {
  color: white;
  line-height: 1em;
  text-align: right !important;
}

.Card .cardFrame p {
  color: #fff;
  font-size: .8em;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.Card .actions {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 15px;
  color: #fff;
}

.Card .actions button.heartFill > svg {
  color: var(--mainColor);
}

.Card .actions svg,
.Card .actions button {
  color: #fff;
  font-size: 20px;
  background: none;
  border: none;
}

.Card .actions svg:hover,
.Card .actions button:hover {
  color: var(--mainColor);
}
