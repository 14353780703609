.login,
.signup {
    .logo {
        width: 80px;
        margin: 0 auto;
    }

    form {
        display: flex;
        flex-direction: column;
        max-width: 800px;
        margin: 0 auto;
        text-align: center;
        padding: 30px;
        gap: 10px;

        .inputField {
            position: relative;
            // width: 100%;

            label {
                font-size: 12px;
                position: absolute;
                top: -0.8ex;
                right: 5ex;
                z-index: 1;
                background-color: rgba(255, 255, 255, 0);
                backdrop-filter: blur(5px);
                padding: 0 5px;
            }

            svg.iconError {
                position: absolute;
                right: -25px;
                top: 7px;
                transform: translate(0, 50%);
                font-size: 15px;
            }

            input {
                border: 1px solid gray;
                border-radius: 6px;
                position: relative;
                line-height: 6ex;
                width: 100%;
                padding: 0 10px;

                ::placeholder {
                    color: #78787880;
                }
            }

            .checkbox {
                transform: scale(150%);
                width: fit-content;
            }
        }

        button {
            background-color: var(--level2);
            border-radius: 10px;
            padding: 10px;
            stroke: none;
            border: rgba(255, 255, 255, 0.137) 1px solid;
            outline: none;
            width: 100%;
        }

        .validationError {
            color: #ff4100;
            text-align: right;
            font-size: 14px;
            text-align: center;
            max-width: 70%;
            margin: 0 auto;
        }
    }
}