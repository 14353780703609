.App {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 50px;
  max-width: 100%;
}

.App .frame {
  opacity: 1;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.App .frame h1 {
  font-size: clamp(40px, 7vw, 65px);
  text-align: center;
  line-height: initial;
}

.App .frame h2 {
  font-size: clamp(20px, 2vw, 35px);
  text-align: center;
}

.App .frame h3 {
  font-size: clamp(17px, 1.5vw, 28px);
  text-align: center;
  line-height: initial;
}

.App .frame p {
  font-size: clamp(13px, 2vw, 16px);
}

.Home>h2 {
  text-align: center;
}

.ErrorPage {
  text-align: center;
  height: calc(100vh - 80px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  line-height: 100px;
}

.ErrorPage h1 {
  font-size: clamp(40px, 20vw, 200px) !important;
}

.btnMode {
  background-color: var(--mainColor);
  color: var(--dark);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px;
  border: none;
}

