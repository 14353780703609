.App {
    box-sizing: border-box;
    padding-top: 50px;
    max-width: 100%;

    .frame {
        h1 {
            font-size: clamp(40px, 7vw, 65px);
            text-align: center;
            line-height: initial;
        }

        h2 {
            font-size: clamp(20px, 2vw, 35px);
            text-align: center;
        }

        h3 {
            font-size: clamp(17px, 1.5vw, 28px);
            text-align: center;
            line-height: initial;
        }

        p {
            font-size: clamp(13px, 2vw, 16px);
        }

        opacity: 1;
        width: 100%;
        min-height: 100vh;
        height: 100%;
    }
}

.Home>h2 {
    text-align: center;
}

.ErrorPage {
    text-align: center;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
        font-size: clamp(40px, 20vw, 200px) !important;
    }

    line-height: 100px;
}


.btnMode {
    background-color: var(--level2);
    color: var(--dark);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border: none;
}