.Footer {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    width: 100%;
    min-height: 30px;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    z-index: 1;

    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        list-style: none;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                font-size: 22px;
            }
        }
    }
}