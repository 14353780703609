nav .userArea .profile-open {
  top: 5px;
  left: 20px;
  gap: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  border-radius: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px var(--mainColor) solid;
  width: clamp(220px, 70vw, 300px);
  -webkit-box-shadow: 0 0 50px -30px black;
          box-shadow: 0 0 50px -30px black;
}

nav .userArea .profile-open a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

nav .userArea .profile-open a .permissionTag {
  font-size: 13px;
  padding: 3px 8px;
  border-radius: 5px;
  background-color: var(--mainColor);
  -webkit-box-shadow: 0 0 30px -5px var(--mainColor);
          box-shadow: 0 0 30px -5px var(--mainColor);
}

nav .userArea .profile-open ul {
  gap: 10px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

nav .userArea .profile-open ul li {
  font-size: 14px !important;
}

nav .userArea .profile-open ul a {
  gap: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: inherit;
  width: inherit;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 5px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  text-decoration: none;
  -webkit-transition: .1s ease-in;
  transition: .1s ease-in;
}

nav .userArea .profile-open ul .logoutBtn {
  background-color: #b717177e;
  display: block;
  text-align: center;
  color: white !important;
}

nav .userArea .profile-open ul .logoutBtn:hover {
  background-color: #b71717c9;
  -webkit-box-shadow: 0 0 30px -5px red;
          box-shadow: 0 0 30px -5px red;
  font-size: 120% !important;
  color: white;
}

nav .userArea .profile-open ul a:hover {
  background-color: #e9e4ff86;
}

nav .userArea .profile-open .palette {
  padding: 10px;
  width: 100%;
  background-color: #8d8d8d19;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

nav .userArea .profile-open .palette p {
  text-align: center;
}

nav .userArea .profile-open .palette .colorPalette {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

nav .userArea .profile-open .palette .colorPalette .colorIcon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 14px;
  width: 14px;
  border-radius: 50%;
}

nav .userArea .profile-open .palette .colorPalette .colorIcon.blue {
  background-color: #5BCDFC;
}

nav .userArea .profile-open .palette .colorPalette .colorIcon.red {
  background-color: #eb6e6e;
}

nav .userArea .profile-open .palette .colorPalette .colorIcon.yellow {
  background-color: #cccc41;
}

nav .userArea .profile-open .palette .colorPalette .colorIcon.green {
  background-color: #7bcc7b;
}
