nav {
    height: 50px;
    max-height: 70px;
    width: 100%;
    position: fixed;
    top: 0;
    backdrop-filter: blur(18px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    box-shadow: 0 0 30px -10px black;
    z-index: 999;



    ul {
        display: flex;
        list-style: none;
        gap: 8px;

        li {
            display: inline;
            font-size: clamp(13px, 2vw, 22px);

            a {
                background-color: rgba(154, 154, 154, 0.288);
                border-radius: 8px;
                text-decoration: none;
                padding: 2px 8px;
            }
        }
    }

    .navigator {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;

        a {
            position: relative;
            background-color: transparent;
            margin: 0 5px;
            padding: 5px 15px;
            font-size: 20px !important;

            &::before {
                position: absolute;
                bottom: 0;
                right: 0;
                content: "";
                border-radius: 0;
                background-color: var(--mainColor);
                height: 2px;
                width: 100%;
                transition: all .1s ease-in;
                opacity: .5;
                z-index: 1;
            }

            &.currentPage::before,
            &:hover::before {
                height: 100%;
                border-radius: 5px;
                opacity: 1;
            }

            span {
                position: relative;
                z-index: 2;
            }
        }
    }

    .leftSide {

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        width: 140px;

        .searchInput {
            border: none;
            background-color: rgba(154, 154, 154, 0.288);
            border-radius: 8px;
            padding: 4px 8px;
        }

        .userArea {
            width: fit-content;
            z-index: 2000;

            .avatar {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(95, 158, 160, 0.589);

                .img-avatar {
                    width: inherit;
                    height: inherit;
                    object-fit: cover;
                }
            }
        }
    }
}