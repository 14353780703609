nav {
    .userArea {
        .profile-open {
            top: 5px;
            left: 20px;
            gap: 20px;
            display: flex;
            padding: 20px;
            height: fit-content;
            position: absolute;
            border-radius: 20px;
            align-items: center;
            flex-direction: column;
            border: 1px var(--mainColor) solid;
            width: clamp(220px, 70vw, 300px);
            box-shadow: 0 0 50px -30px black;

            a {
                display: flex;
                align-items: center;
                flex-direction: column;

                .permissionTag {
                    font-size: 13px;
                    padding: 3px 8px;
                    border-radius: 5px;
                    background-color: var(--mainColor);
                    box-shadow: 0 0 30px -5px var(--mainColor);
                }
            }

            ul {
                gap: 10px;
                width: 100%;
                display: flex;
                flex-direction: column;

                li {
                    font-size: 14px !important;
                }

                a {
                    gap: 10px;
                    display: flex;
                    color: inherit;
                    width: inherit;
                    cursor: pointer;
                    padding: 5px 15px;
                    border-radius: 5px;
                    flex-direction: row;
                    text-decoration: none;
                    transition: .1s ease-in;
                }

                .logoutBtn {
                    background-color: #b717177e;
                    display: block;
                    text-align: center;
                    color: white !important;
                }

                .logoutBtn:hover {
                    background-color: #b71717c9;
                    box-shadow: 0 0 30px -5px red;
                    font-size: 120% !important;
                    color: white;
                }

                a:hover {
                    background-color: #e9e4ff86;
                }
            }

            .palette {
                padding: 10px;
                width: 100%;
                background-color: #8d8d8d19;
                display: flex;
                flex-direction: column;
                gap: 10px;
                border-radius: 10px;
                align-items: center;

                p {
                    text-align: center;
                }

                .colorPalette {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    .colorIcon {

                        display: flex;
                        flex-direction: column;
                        height: 14px;
                        width: 14px;
                        border-radius: 50%;

                        &.blue {
                            background-color: #5BCDFC;
                        }

                        &.red {
                            background-color: #eb6e6e;
                        }

                        &.yellow {
                            background-color: #cccc41;
                        }

                        &.green {
                            background-color: #7bcc7b;
                        }
                    }

                }
            }



        }
    }
}