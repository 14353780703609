.CardsList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: scroll;
}

.CardsList .addCardBtn {
  padding: 5px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
}

.CardsList .addCardBtn:hover {
  background-color: var(--mainColor);
  cursor: pointer;
}

.CardsList a {
  text-decoration: none;
}

.CardsList .tableFrame {
  width: 100%;
  overflow: scroll;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.CardsList .tableFrame table {
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0px 5px;
}

.CardsList .tableFrame table thead tr {
  height: 30px;
  background-color: #e9e9e9;
}

.CardsList .tableFrame table thead th {
  font-weight: 800;
  font-size: 16px;
}

.CardsList .tableFrame table tbody tr td {
  text-align: right;
  padding: 10px 40px;
  font-size: 14px;
}

.CardsList .tableFrame table tbody tr td img {
  height: 35px;
  width: 35px;
  -o-object-position: center;
  object-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
  font-size: 14px;
}

.CardsList .tableFrame table tbody tr td .actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}

.CardsList .tableFrame table tbody tr td .actions svg {
  font-size: 20px;
}

