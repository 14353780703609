//BIG SCREEN //////////////////////////////////
@media (min-width: 768px) {

    nav {
        .menuToggle {
            display: none;
        }

        .navigator {
            ul {
                display: flex;
                flex-direction: row;
            }
        }
    }

    .Cards {
        .cardsList {
            justify-content: center;
        }
    }

    .AddCard,
    .EditCard {
        .form {
            padding: 50px 60px;
        }
    }

    .CardPage {
        .frmae {
            min-height: 50vh;

            .textHeader {
                max-width: 70%;
            }
        }

        .content {
            padding: 30px 50px;
        }
    }

    .Footer {
        display: none !important;
    }
}

//SMALL SCREEN //////////////////////////////////
@media (max-width: 767px) {

    nav {
        .menuToggle {
            display: flex;
        }

        .searchInput {
            display: none;
        }

        .navigator {
            ul {
                display: none;
            }
        }

        .navigator.showMenu {
            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                height: fit-content;
                width: 99%;
                max-width: 100%;
                position: absolute;
                top: 50px;
                left: 50%;
                transform: translateX(-50%);
                padding: 80px 20px;
                box-shadow: 0px 40px 70px -70px black;

                a::before {
                    display: none;
                }
            }
        }
    }

    .Cards {
        .cardsList {
            flex-wrap: nowrap;
            overflow: scroll;
            width: 100%;
        }
    }

    .AddCard,
    .EditCard {
        .display {
            display: none;
        }

        .form {
            width: 100%;
            padding: 50px 20px;

        }
    }

    .CardPage {
        .frmae {
            min-height: 40vh;
            padding: 20px;

            .textHeader {
                max-width: 300px;
            }
        }

        .content {
            padding: 20px 5%;
        }
    }


}

//EXTRA SMALL SCREEN //////////////////////////////////
@media (max-width: 330px) {

    .CardPage {
        .frmae {
            min-height: 40vh;
            padding: 20px;

            .textHeader {
                max-width: 300px;
            }
        }
    }
}