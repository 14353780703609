.Favorite {
    .errorFavorite {
        text-align: center;

        svg {
            font-size: 2em;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .loginBtn {
            padding: 0 10px;
            display: block;
            font-size: .9em;
            background-color: var(--level2);
            border-radius: 5px;
            border: none;
            text-align: center;
            margin: 0 auto;
        }
    }
}