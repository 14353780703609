.clientList {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .tableFrame {
        width: 100%;
        overflow: scroll;
        padding: 20px;
        display: flex;

        table {
            margin: 0 auto;
            border-collapse: separate;
            border-spacing: 0px 5px;

            thead {
                tr {
                    height: 30px;
                    background-color: #e9e9e9;
                }

                th {
                    font-weight: 800;
                    font-size: 16px;
                }
            }

            tbody {

                tr {
                    td {
                        text-align: right;
                        padding: 10px 40px;
                        font-size: 14px;

                        &:first-child {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            font-weight: 700;
                        }

                        img {
                            height: 35px;
                            width: 35px;
                            object-position: center;
                            object-fit: cover;
                            border-radius: 50%;
                            font-size: 14px;
                        }

                        .deleteUser {
                            background: none;
                            border: none;
                            color: red;
                            font-size: 20px;
                            display: flex;
                            transition: .2s ease-in;

                            &:hover {
                                scale: 130%;
                            }
                        }

                        select {
                            background: none;
                            border-radius: 5px;
                            padding: 2px 7px;
                            border: 1px solid var(--level2);
                        }
                    }

                }
            }
        }
    }
}