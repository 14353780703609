.AddCard,
.EditCard {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    .display {
        background-image: radial-gradient(#7c7c7c4d 1.8px, #ffffff00 1.8px);
        background-size: 36px 36px;
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 40px;
        border-right: solid 1px #999999;
    }

    .form {
        width: 70%;

        h2 {
            display: block;
        }

        form {
            display: flex;
            flex-direction: column;

            label {
                font-size: 14px;
                padding: 0 5px;
                margin-top: 10px;
            }

            svg.iconError {
                position: relative;
                color: yellow;
                font-size: 15px;
            }

            input,
            textarea {
                align-self: flex-end;
                border: 1px solid gray;
                border-radius: 10px;
                position: relative;
                line-height: 5ex;
                padding: 0 10px;
                width: 100%;
                height: fit-content;
                resize: vertical;
                border: solid 1px var(--level2);

                ::placeholder {
                    color: #78787880;
                }
            }

            textarea {
                line-height: 3ex;
                padding: 10px;
            }

            .checkbox {
                transform: scale(150%);
            }
        }

        button {
            margin-top: 15px;
            background-color: var(--level2);
            border-radius: 10px;
            padding: 10px;
            stroke: none;
            border: rgba(255, 255, 255, 0.137) 1px solid;
            border: none;
            outline: none;
            width: 100%;
            cursor: pointer;
        }

        .validationError {
            color: red;
            text-align: right;
            font-size: 14px;
        }
    }
}