nav {
  height: 50px;
  max-height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  -webkit-backdrop-filter: blur(18px);
          backdrop-filter: blur(18px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 30px;
  -webkit-box-shadow: 0 0 30px -10px black;
          box-shadow: 0 0 30px -10px black;
  z-index: 999;
}

nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  gap: 8px;
}

nav ul li {
  display: inline;
  font-size: clamp(13px, 2vw, 22px);
}

nav ul li a {
  background-color: rgba(154, 154, 154, 0.288);
  border-radius: 8px;
  text-decoration: none;
  padding: 2px 8px;
}

nav .navigator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

nav .navigator a {
  position: relative;
  background-color: transparent;
  margin: 0 5px;
  padding: 5px 15px;
  font-size: 20px !important;
}

nav .navigator a::before {
  position: absolute;
  bottom: 0;
  right: 0;
  content: "";
  border-radius: 0;
  background-color: var(--mainColor);
  height: 2px;
  width: 100%;
  -webkit-transition: all .1s ease-in;
  transition: all .1s ease-in;
  opacity: .5;
  z-index: 1;
}

nav .navigator a.currentPage::before, nav .navigator a:hover::before {
  height: 100%;
  border-radius: 5px;
  opacity: 1;
}

nav .navigator a span {
  position: relative;
  z-index: 2;
}

nav .leftSide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  width: 140px;
}

nav .leftSide .searchInput {
  border: none;
  background-color: rgba(154, 154, 154, 0.288);
  border-radius: 8px;
  padding: 4px 8px;
}

nav .leftSide .userArea {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 2000;
}

nav .leftSide .userArea .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(95, 158, 160, 0.589);
}

nav .leftSide .userArea .avatar .img-avatar {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
     object-fit: cover;
}
