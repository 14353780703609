.login .logo,
.signup .logo {
  width: 80px;
  margin: 0 auto;
}

.login form,
.signup form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
  gap: 10px;
}

.login form .inputField,
.signup form .inputField {
  position: relative;
}

.login form .inputField label,
.signup form .inputField label {
  font-size: 12px;
  position: absolute;
  top: -0.8ex;
  right: 5ex;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 0 5px;
}

.login form .inputField svg.iconError,
.signup form .inputField svg.iconError {
  position: absolute;
  right: -25px;
  top: 7px;
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
  font-size: 15px;
}

.login form .inputField input,
.signup form .inputField input {
  border: 1px solid gray;
  border-radius: 6px;
  position: relative;
  line-height: 6ex;
  width: 100%;
  padding: 0 10px;
}

.login form .inputField input ::-webkit-input-placeholder,
.signup form .inputField input ::-webkit-input-placeholder {
  color: #78787880;
}

.login form .inputField input :-ms-input-placeholder,
.signup form .inputField input :-ms-input-placeholder {
  color: #78787880;
}

.login form .inputField input ::-ms-input-placeholder,
.signup form .inputField input ::-ms-input-placeholder {
  color: #78787880;
}

.login form .inputField input ::placeholder,
.signup form .inputField input ::placeholder {
  color: #78787880;
}

.login form .inputField .checkbox,
.signup form .inputField .checkbox {
  -webkit-transform: scale(150%);
  transform: scale(150%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.login form button,
.signup form button {
  background-color: var(--mainColor);
  border-radius: 10px;
  padding: 10px;
  stroke: none;
  border: rgba(255, 255, 255, 0.137) 1px solid;
  outline: none;
  width: 100%;
}

.login form .validationError,
.signup form .validationError {
  color: #ff4100;
  text-align: right;
  font-size: 14px;
  text-align: center;
  max-width: 70%;
  margin: 0 auto;
}

