.Snackbar {
    height: fit-content;
    width: fit-content;
    min-width: 200px;
    max-width: 500px;
    padding: 5px 10px;
    background-color: var(--mainColor);
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 50px;
    right: 50%;
    transform: translate(50%, 200%);
    border-radius: 5px;
    transition: .4s ease-in;
    gap: 10px;
    opacity: 0;
    z-index: 9999;
    box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.391);
}

.Snackbar p,
.Snackbar svg {
    color: black;
}

.Snackbar.show {
    transform: translate(50%, 00%);
    opacity: 1;
}