.Card {
    background-size: cover;
    border-radius: 15px;
    min-width: 250px;
    width: 250px;
    min-height: 400px;
    box-shadow: 0 0 30px -20px black;
    background-color: #fff;
    background-position: center;

    .cardFrame {
        background-image: linear-gradient(#ffffff00, #000);
        border-radius: 15px;
        padding: 25px;
        backdrop-filter: brightness(.9);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow: scroll;

        a {
            text-decoration: none;
            font-size: 1em;

            :hover {
                text-decoration: underline;
            }
        }

        h2 {
            color: rgb(255, 255, 255);
            line-height: 1em;
            text-align: right !important;
        }

        p {
            color: #fff;
            font-size: .8em;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    .actions {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 15px;
        color: #fff;


        button.heartFill>svg {
            color: var(--mainColor);
        }

        svg,
        button {
            color: #fff;
            font-size: 20px;
            background: none;
            border: none;


            &:hover {
                color: var(--mainColor);
            }
        }


    }




}