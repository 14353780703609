.Cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Cards .addCardBtn {
  padding: 5px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
}

.Cards .addCardBtn:hover {
  background-color: var(--mainColor);
  cursor: pointer;
}

.Cards a {
  text-decoration: none;
}

.Cards .cardsList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  gap: 15px;
  padding: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.MyCards .emptyMyCards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

